var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { title: _vm.$t("Filtros"), searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "company-id",
                      type: "text",
                      label: _vm.$t("Id"),
                    },
                    model: {
                      value: _vm.filters.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "id", $$v)
                      },
                      expression: "filters.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "trading-name",
                      type: "text",
                      label: _vm.$t("Nome fantasia"),
                    },
                    model: {
                      value: _vm.filters.tradingName,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "tradingName", $$v)
                      },
                      expression: "filters.tradingName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "company-name",
                      type: "text",
                      label: _vm.$t("Razão social"),
                    },
                    model: {
                      value: _vm.filters.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "companyName", $$v)
                      },
                      expression: "filters.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "active",
                      type: "vue-select",
                      label: _vm.$t("Status"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.activeInactiveTypes(),
                    },
                    model: {
                      value: _vm.filters.active,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "active", $$v)
                      },
                      expression: "filters.active",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "cnpj",
                      type: "text-mask",
                      mask: ["##.###.###/####-##"],
                      label: _vm.$t("CNPJ"),
                    },
                    model: {
                      value: _vm.filters.cnpj,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "cnpj", $$v)
                      },
                      expression: "filters.cnpj",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "headId",
                      type: "vue-select",
                      label: _vm.$t("Head"),
                      options: _vm.headOptions,
                      placeholder: _vm.$t("Todos"),
                    },
                    model: {
                      value: _vm.filters.headId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "headId", $$v)
                      },
                      expression: "filters.headId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "headId",
                      type: "vue-select",
                      label: _vm.$t("Diretor"),
                      options: _vm.directorOptions,
                      placeholder: _vm.$t("Todos"),
                    },
                    model: {
                      value: _vm.filters.directorId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "directorId", $$v)
                      },
                      expression: "filters.directorId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "email",
                      type: "text",
                      label: _vm.$t("E-mail"),
                    },
                    model: {
                      value: _vm.filters.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "email", $$v)
                      },
                      expression: "filters.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "public-place",
                      type: "text",
                      label: _vm.$t("Logradouro"),
                    },
                    model: {
                      value: _vm.filters.publicPlace,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "publicPlace", $$v)
                      },
                      expression: "filters.publicPlace",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "region",
                      type: "vue-select",
                      label: _vm.$t("Região"),
                      options: _vm.storeRegions(),
                      placeholder: _vm.$t("Selecione"),
                    },
                    model: {
                      value: _vm.filters.region,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "region", $$v)
                      },
                      expression: "filters.region",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "province",
                      type: "vue-select",
                      label: _vm.$t("Estado"),
                      options: _vm.localProvinces,
                    },
                    model: {
                      value: _vm.filters.province,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "province", $$v)
                      },
                      expression: "filters.province",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "city",
                      type: "text",
                      label: _vm.$t("Cidade"),
                    },
                    model: {
                      value: _vm.filters.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "city", $$v)
                      },
                      expression: "filters.city",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          ref: "table-card",
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("b-table", {
                    ref: "companies-table",
                    staticClass: "table-responsive bordered",
                    attrs: {
                      id: "companies-table",
                      responsive: "",
                      striped: "",
                      "show-empty": "",
                      "empty-text": _vm.getEmptyTableMessage(
                        _vm.$tc("COMPANY.NAME"),
                        "female"
                      ),
                      items: _vm.companies,
                      fields: _vm.fields,
                      "no-local-sorting": "",
                      "sort-by": _vm.sorting.sortBy,
                      "sort-desc": _vm.sorting.sortDesc,
                    },
                    on: {
                      "update:sortBy": function ($event) {
                        return _vm.$set(_vm.sorting, "sortBy", $event)
                      },
                      "update:sort-by": function ($event) {
                        return _vm.$set(_vm.sorting, "sortBy", $event)
                      },
                      "update:sortDesc": function ($event) {
                        return _vm.$set(_vm.sorting, "sortDesc", $event)
                      },
                      "update:sort-desc": function ($event) {
                        return _vm.$set(_vm.sorting, "sortDesc", $event)
                      },
                      "sort-changed": _vm.getData,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(actions)",
                        fn: function (row) {
                          return [
                            _c("e-grid-actions", {
                              attrs: {
                                "show-delete": false,
                                "show-update": _vm.$can("Update", "Store"),
                              },
                              on: {
                                update: function ($event) {
                                  return _vm.edit(row.item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(active)",
                        fn: function (ref) {
                          var value = ref.value
                          return [
                            _c("e-status-badge", { attrs: { status: value } }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c("e-pagination-summary", {
                            attrs: {
                              "current-page": _vm.paging.currentPage,
                              "per-page": _vm.paging.pageSize,
                              total: _vm.paging.rowCount,
                              "total-on-page": _vm.paging.rowsInCurrentPage,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c("b-pagination", {
                            attrs: {
                              align: "right",
                              "total-rows": _vm.paging.rowCount,
                              "per-page": _vm.paging.pageSize,
                              "aria-controls": "product-table",
                            },
                            on: { change: _vm.pageChange },
                            model: {
                              value: _vm.paging.currentPage,
                              callback: function ($$v) {
                                _vm.$set(_vm.paging, "currentPage", $$v)
                              },
                              expression: "paging.currentPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "Store")
        ? _c("FAB", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Loja") },
            on: { click: _vm.create },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }