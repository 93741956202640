<template>
  <div>
    <e-filters
      :title="$t('Filtros')"
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="2">
          <FormulateInput
            id="company-id"
            v-model="filters.id"
            type="text"
            :label="$t('Id')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="trading-name"
            v-model="filters.tradingName"
            type="text"
            :label="$t('Nome fantasia')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="company-name"
            v-model="filters.companyName"
            type="text"
            :label="$t('Razão social')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="active"
            v-model="filters.active"
            type="vue-select"
            :label="$t('Status')"
            :placeholder="$t('Todos')"
            :options="activeInactiveTypes()"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="cnpj"
            v-model="filters.cnpj"
            type="text-mask"
            :mask="['##.###.###/####-##']"
            :label="$t('CNPJ')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="headId"
            v-model="filters.headId"
            type="vue-select"
            :label="$t('Head')"
            :options="headOptions"
            :placeholder="$t('Todos')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="headId"
            v-model="filters.directorId"
            type="vue-select"
            :label="$t('Diretor')"
            :options="directorOptions"
            :placeholder="$t('Todos')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="email"
            v-model="filters.email"
            type="text"
            :label="$t('E-mail')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="public-place"
            v-model="filters.publicPlace"
            type="text"
            :label="$t('Logradouro')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="region"
            v-model="filters.region"
            type="vue-select"
            :label="$t('Região')"
            :options="storeRegions()"
            :placeholder="$t('Selecione')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="province"
            v-model="filters.province"
            type="vue-select"
            :label="$t('Estado')"
            :options="localProvinces"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="city"
            v-model="filters.city"
            type="text"
            :label="$t('Cidade')"
          />
        </b-col>
      </b-row>
    </e-filters>
    <b-card-actions
      ref="table-card"
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-row>
        <b-col md="12">
          <b-table
            id="companies-table"
            ref="companies-table"
            class="table-responsive bordered"
            responsive
            striped
            show-empty
            :empty-text="getEmptyTableMessage($tc('COMPANY.NAME'), 'female')"
            :items="companies"
            :fields="fields"
            no-local-sorting
            :sort-by.sync="sorting.sortBy"
            :sort-desc.sync="sorting.sortDesc"
            @sort-changed="getData"
          >
            <template #cell(actions)="row">
              <e-grid-actions
                :show-delete="false"
                :show-update="$can('Update', 'Store')"
                @update="edit(row.item)"
              />
            </template>
            <template #cell(active)="{ value }">
              <e-status-badge :status="value" />
            </template>
          </b-table>
          <b-row>
            <b-col md="6">
              <e-pagination-summary
                :current-page="paging.currentPage"
                :per-page="paging.pageSize"
                :total="paging.rowCount"
                :total-on-page="paging.rowsInCurrentPage"
              />
            </b-col>
            <b-col md="6">
              <b-pagination
                v-model="paging.currentPage"
                align="right"
                :total-rows="paging.rowCount"
                :per-page="paging.pageSize"
                aria-controls="product-table"
                @change="pageChange"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-actions>
    <FAB
      v-if="$can('Create', 'Store')"
      :main-tooltip="$t('Adicionar Loja')"
      @click="create"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { BTable, BPagination, BRow, BCol } from 'bootstrap-vue'
import { FAB, EPageSizeSelector, EPaginationSummary, EFilters } from '@/views/components'

import {
  companyDomains,
  downloader,
  province,
  statusTypes,
  storeDomains,
  translate,
} from '@/mixins'
import EGridActions from '@/views/components/EGridActions.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'

export default {
  components: {
    BCardActions,
    BTable,
    BPagination,
    BRow,
    BCol,
    EPageSizeSelector,
    EPaginationSummary,
    FAB,
    EFilters,
    EGridActions,
    EStatusBadge,
  },
  mixins: [downloader, province, companyDomains, storeDomains, statusTypes, translate],
  data() {
    return {
      fetching: false,
      loading: false,
      exporting: false,
      orderBy: {
        key: 'dadoContrato.dataAdesao',
        direction: 'desc',
      },
    }
  },
  computed: {
    ...mapGetters('app', ['statesWithStore']),
    ...mapGetters('pages/company/companyMaintain', ['headOptions', 'directorOptions']),
    ...mapState('pages/company/companyMaintain', {
      companies: 'companies',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),
    localProvinces() {
      return this.provinces().filter(
        provinceItem => this.statesWithStore.indexOf(provinceItem.value) >= 0
      )
    },
    rows() {
      return this.paging.totalRows || this.companies.length
    },
    fields() {
      return [
        {
          key: 'actions',
          label: this.$t('Ações'),
          class: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          key: 'cnpj',
          label: this.$t('Cnpj'),
          thStyle: 'width: 170px',
          formatter: value => this.$options.filters.cpfCnpj(value) || '-',
        },
        {
          key: 'tradingName',
          label: this.$t('Nome Fantasia'),
          formatter: (val, key, item) => this.$options.filters.storeName(item),
          sortable: true,
        },
        {
          key: 'type',
          label: this.$t('Tipo'),
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.companyTypesLabel[value] || '-',
          sortable: true,
        },
        {
          key: 'address.province',
          label: this.$t('Estado'),
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => value || '-',
          sortable: true,
        },
        {
          key: 'region',
          label: this.$t('Região'),
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => value || '-',
          sortable: true,
        },
        {
          key: 'address.city',
          label: this.$t('Cidade'),
          thStyle: { width: '300px' },
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
      ]
    },
  },
  mounted() {
    this.getData()
    this.fetchHeads()
    this.fetchDirectors()
  },
  methods: {
    ...mapActions('pages/company/companyMaintain', [
      'fetchCompanies',
      'fetchHeads',
      'fetchDirectors',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
      'clearStore',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchCompanies()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
    create() {
      this.clearStore()
      this.$router.push({ name: 'company-add' })
    },
    edit(company) {
      this.clearStore()
      this.$router.push({
        name: 'company-maintain',
        params: { id: company.id },
      })
    },
  },
}
</script>
